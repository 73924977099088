<template>
  <div class="">
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot pb-2">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-2">MVR Reports</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-4">
                    <div class="data-tablebutn pb-3">
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                        <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                      </b-button>
                    </div>
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="all">Member Id</th>
                        <th class="all">First Name</th>
                        <th class="all">Last Name</th>
                        <th class="all">Email</th>
                        <th class="all">Start Date</th>
                        <th class="all">End Date</th>
                        <th class="all">Coupon Code</th>
                        <th class="all">Purchase Date</th>
                        <th class="none">Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- Data will be placed here -->
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                    <form ref="form" method="post" @submit.prevent="advancedSearch()">
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">First name</label>
                              <input type="text" id="first_name" name="first_name" class="form-control" v-model="searchForm.first_name">
                              <div class="text-sm text-danger">{{ errors.first_name }}</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Last Name</label>
                              <input type="text" id="last_name" name="last_name" class="form-control" v-model="searchForm.last_name">
                              <div class="text-sm text-danger">{{ errors.last_name }}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Email</label>
                              <input type="email" id="email" name="email" class="form-control" v-model="searchForm.email">
                              <div class="text-sm text-danger">{{ errors.email }}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Purchase Date</label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <date-pick
                                      v-model="searchForm.purchase_date"
                                      :format="'MM/DD/YYYY'"
                                      :displayFormat="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                </div>
                              </div>
                              <input id="purchase_date" type="hidden" class="form-control" :v-model="searchForm.purchase_date" >
                              <div class="text-sm text-danger">{{ errors.purchase_date }}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">From Date</label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <date-pick
                                      v-model="searchForm.start_date"
                                      :format="'MM/DD/YYYY'"
                                      :displayFormat="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                </div>
                              </div>
                              <input id="start_date" type="hidden" class="form-control" :value="searchForm.start_date">
                              <div class="text-sm text-danger">{{ errors.start_date }}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">To Date</label>
                              <div class="input-group birthdate">
                                <div class="birthdaypicker">
                                  <date-pick
                                      v-model="searchForm.end_date"
                                      :format="'MM/DD/YYYY'"
                                      :displayFormat="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                </div>
                              </div>
                              <input id="end_date" type="hidden" class="form-control" :value="searchForm.end_date">
                              <div class="text-sm text-danger">{{ errors.end_date }}</div>
                            </div>
                          </div>

                          

                          
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Member Id</label>
                              <input type="text" id="confirmation_code" name="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                              <div class="text-sm text-danger">{{ errors.confirmation_code }}</div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Status</label>
                              <Select2 v-model="searchForm.bgStatus" :options="bgStatuses" name="bgStatus" id="bgStatus" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"/>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-label">Coupon Code</label>
                              <Select2 v-model="searchForm.coupon_id" :options="couponcode" name="coupon_id" id="coupon_id" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"/>
                            </div>
                          </div>
                      </div>
                      <div class="col-md-12 modal-searchbtn text-center">
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                        </div>
                        <div class="text-sm text-danger">{{ errors.not_found }}</div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->
    <Footer></Footer>
  </div>
</template>

<script>
// import DownloadExcel from "vue-json-excel";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from 'moment';
import $ from "jquery";
import axios from 'axios';

const validateName = name => {
  if ( name != "" || name.length ) {
    return { valid: true, error: null };
  }
  return { valid: false, error: null };
};

let cachedData = {};
export default {
  components: {  },
  data() {
    return {
      SreeningType:'',
      searchForm : {
        confirmation_code:'',
        first_name: '',
        last_name: '',
        email: '',
        start_date: '',
        coupon_id:'',
        end_date: '',
        purchase_date: '',
        bgStatus:[],
      },
      exportProgress: false,
      filtersApplied: false,
      loaded: false,
      perPage: 10,
      currentPage: 1,
      items: [],
      couponcode:[],
      bgStatuses: [
        {id:0 , text:'Not Started'},
        {id:1 , text:'In Progress'},
        {id:2 , text:'Complete'},
        {id:3 , text:'Canceled'},
        {id:4 , text:'Error'},
        {id:5 , text:'paymentPending'},
        {id:6 , text:'Expired'},
        {id:7 , text:'In Complete'}
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      button: {
        SearchButton: 'Search'
      },
      errors: {},
      table: {},
    }
  },
  methods: {
    advancedSearch() {

      this.button.SearchButton = 'Search';
      this.loaded = false;
      this.table.draw();
      this.filtersApplied = true;
      this.$refs['modal-member-report'].hide();
      this.button.SearchButton = 'Search';
      // this.button.SearchButton = 'Search';
    },
    // Custom Validation Rules
    
    refreshList(event){
      this.table.clear().draw();
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    exportCsv(){
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_URL + "api/reports/export/mvr-and-bg-screen/mvr",
        type: 'post',
        "data": this.table.ajax.params(),
         beforeSend: function(){
                    self.exportProgress = true;
                },
                "success": function(res, status, xhr) {
                    self.exportProgress = false;
                    if(res.file_url != undefined){
                      window.open(res.file_url, '_blank');
                    }  
                },
                error: function(err){
                    console.log(err);
                    self.exportProgress = false;
                }
      });
    },
    getCoupon() {
        axios
            .get(this.basePath + "api/mvr-bg-screen/get-coupons")
            .then((response) => {
            let coupon = response.data.coupon_list;
            let coupon_codes = Object.keys(coupon).sort();
              coupon_codes.forEach(code => {
                  this.couponcode.push({ 'id': code, 'text': coupon[code] });
              });
            });        
    },
  },
  mounted(){
    this.getCoupon();
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
    var self = this;
    var type = self.searchForm.membership_type
    this.table = $("#list_table").DataTable({
      sDom: "lfrtip",
      processing: true,
      serverSide: true,
      pageLength: 10,
      "bFilter": true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/reports/mvr-and-bg-screening-reports/mvr",
        type: 'post',
        data: function (d) {
          d.start_date = $('#start_date').val() || self.searchForm.start_date;
          d.end_date = $('#end_date').val() || self.searchForm.end_date;
          d.purchase_date = $('#purchase_date').val() || self.searchForm.purchase_date;
          d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
          d.bgStatus = $('#bgStatus').val() || self.searchForm.bgStatus;
          d.first_name = $('#first_name').val() || self.searchForm.first_name;
          d.last_name = $('#last_name').val() || self.searchForm.last_name;
          d.email = $('#email').val() || self.searchForm.email;
          d.coupon_id = $('#coupon_id').val() || self.searchForm.coupon_id;
        }
      },
      columns: [
        { data: 'confirmation_code',  orderable:true, searchable:true, name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
        { data: "first_name",  orderable:false, searchable:true, name: "ncsi.first_name", render: (data)=>{return data || "N/A"} },
        { data: "last_name",  orderable:false, searchable:true, name: "ncsi.last_name", render: (data)=>{return data || "N/A"} },
        { data: "email",  orderable:false, searchable:true, name: "ncsi.email", render: (data)=>{return data || "N/A"} },
        { data: "start_date",  orderable:true, searchable:false, name: "start_date", render: (data)=>{return data || "N/A"} },
        { data: "end_date",  orderable:true, searchable:false, name: "expiration", render: (data)=>{return data || "N/A"} },
        { data: "coupon_code",  orderable:false, searchable:true, name: "coupon.code", render: (data)=>{return data || "N/A"} },
        { data: "purchase_date", orderable:false, searchable:false, name: "purchase_date", render: (data)=>{return data || "N/A"} },
        { data: "status",  orderable:false, searchable:false, name: "status", render: (data)=>{return data || "N/A"} }
      ],
      order:[[7,'desc']]
    });
    const year = new Date().getFullYear();
    const yrs = year+20;
    const twoDigitYear = yrs.toString().substr(-2);
    const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
    this.yearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);

  },

  computed: {
    rows() {
      return this.items.length
    },
  }
}

</script>